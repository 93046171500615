import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/homePage';
import Faq from './pages/faq';
import Alibaba from './pages/alibaba';
import Blog from './pages/blog';
import Contact from './pages/contact';
import Services from './pages/services';
import ECommerce from './pages/eCommerce';
import EExport from './pages/eExport';
import AboutUs from './pages/aboutUs';
import BlogDetails from './pages/blogDetails';
import ScrollToTop from './utils/scrollToTop'

function App() {
  return (
    <>
      <Router>
        <ScrollToTop /> 
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/alibaba" element={<Alibaba />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog-detay/:title" element={<BlogDetails />} />
          <Route exact path="/iletisim" element={<Contact />} />
          <Route exact path="/hizmetlerimiz" element={<Services />} />
          <Route exact path="/hakkimizda" element={<AboutUs />} />
          <Route exact path="/e-ticaret" element={<ECommerce />} />
          <Route exact path="/e-ihracat" element={<EExport />} />
          <Route exact path="/sss" element={<Faq />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

