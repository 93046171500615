import React,{useState,useEffect} from "react";
import Layout from "../../layout";
import img2 from "../../assets/gallery/img_main/_0016_headphones.png";
import img3 from "../../assets/gallery/img_main/_0017_list.png";
import img4 from "../../assets/gallery/img_main/_0018_premium_quality.png";
import img5 from "../../assets/gallery/img_main/_0021_check.png";
import { Helmet } from "react-helmet";
import "./index.css";
import ReactHtmlParser from "react-html-parser";

const Alibaba = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/alibaba")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div> </div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "Alibaba, 1999 yılında Çin'de Jack Ma tarafından kurulan bir e-ticaret ve teknoloji şirketidir."
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "Alibaba, 1999 yılında Çin'de Jack Ma tarafından kurulan bir e-ticaret ve teknoloji şirketidir.";
    document.head.appendChild(newMetaTag);
  }
  return (
    <Layout>
      <Helmet>
        <title>Alibaba | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/alibaba`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="page-alibaba bg-alibaba pb-5">
        <h1 className="d-none">Alibaba Crewier</h1>
        <div className="container-fluid d-flex justify-content-center  flex-column mb-5">
          <div className="w-100 text-center alibaba-logo-div">

            <img src="/logo/alibaba_logo.svg" alt="alibaba" />

          </div>
          <div className="row col-lg-12 mt-4 ">
            <div className="col-lg-6 col-md-6 col-12  ">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-end ">
                      {data[0]?.info ? ReactHtmlParser(data[0].info) : "İçerik bulunamadı."}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 ">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-end ">
                      {data[1]?.info ? ReactHtmlParser(data[1].info) : "İçerik bulunamadı."}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row col-lg-12  mt-4 d-flex justify-content-center">
            <div className="col-xl-4 col-lg-6 col-12 position-lg-relative">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-xl-flex ">
                      <div className="d-flex justify-content-center">
                        <img className="three_icon_corporate" src={img2}></img>
                      </div>
                      <div className="d-flex flex-column justify-content-end ">
                        <h3 className="card-text mt-lg-4 mt-4">
                        {data[2]?.info ? ReactHtmlParser(data[2].info) : "İçerik bulunamadı."}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-12 mt-lg-0 mt-4 ">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-between align-items-center">
                    <img className="w-75" src={img3}></img>

                    <h3 className="card-text mt-lg-0 mt-4">
                    {data[3]?.info ? ReactHtmlParser(data[3].info) : "İçerik bulunamadı."}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-12 mt-xl-0 mt-4 position-relative">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-xl-flex">
                      <div className="d-flex justify-content-center">
                        <img
                          className="three_icon_corporate"
                          src={img4}
                          alt="crewier"
                        ></img>
                      </div>
                      <div className="d-flex flex-column justify-content-end ">
                        <h3 className="card-text mt-lg-0 mt-4">
                        {data[4]?.info ? ReactHtmlParser(data[4].info) : "İçerik bulunamadı."}

                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row col-lg-12  mt-4 d-flex justify-content-center">
            <div className="col-12 mt-xl-0 mt-4 position-relative">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-xl-flex">
                      <div className="d-flex justify-content-center">
                        <img
                          className="three_icon_corporate-two"
                          src={img5}
                          alt="crewier"
                        ></img>
                      </div>
                      <div className="d-flex flex-column justify-content-end alibaba-inner-top">
                       
                        <h4 className="card-text mt-lg-0 ">
                        {data[5]?.info ? ReactHtmlParser(data[5].info) : "İçerik bulunamadı."}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Alibaba;
